import React, { useMemo } from 'react';
import { Formik, FormikValues } from 'formik';

import { useTranslation } from '@lyearn/i18n';

import AutoSave from './components/AutoSave';
import FormComponent from './components/Form';
import FormContext from './context/FormContext';
import { FormikSelectorContextProvider } from './context/FormikContextSelector';
import defaultValidate from './helper/defaultValidate';
import getDefaultLayout from './helper/getDefaultLayout';
import FormLayout from './layout/FormLayout';
import getOrderedFieldNames from './utils/getOrderedFieldNames';
import { FormProps } from './types';

function Form<T extends FormikValues = FormikValues>(props: FormProps<T>) {
  const {
    formName,
    validate,
    fields,
    layout,
    sectionLayout,
    initialValues,
    onSubmit,
    setSubmitRef,
    onChange,
    validateOnBlur,
    validateOnChange,
    validateOnMount,
    enableReinitialize = false,
    autoSave = false,
    focusErrorOnSubmit = true,
    debounceMs,
    FormField,
    overrides,
    formRef,
    validateNudgeState,
    children,
  } = props;

  const fieldLayout = sectionLayout || layout || getDefaultLayout(fields);
  const orderedFieldNames = useMemo(() => getOrderedFieldNames(fieldLayout), [fieldLayout]);

  const contextValue = useMemo(
    () => ({ fields, focusErrorOnSubmit, FormField, overrides, orderedFieldNames }),
    [fields, focusErrorOnSubmit, FormField, overrides, orderedFieldNames],
  );

  useTranslation('Form');

  return (
    <Formik
      enableReinitialize={enableReinitialize}
      initialValues={initialValues}
      innerRef={formRef}
      validate={defaultValidate(fields, validate)}
      validateOnBlur={validateOnBlur}
      validateOnChange={validateOnChange}
      validateOnMount={validateOnMount}
      onSubmit={onSubmit}>
      <FormikSelectorContextProvider>
        <FormContext.Provider value={contextValue}>
          {children}
          <FormComponent
            formName={formName}
            setSubmitRef={setSubmitRef}
            validateNudgeState={validateNudgeState}
            onChange={onChange}>
            <FormLayout layout={fieldLayout} />
          </FormComponent>
          {autoSave ? <AutoSave debounceMs={debounceMs} /> : null}
        </FormContext.Provider>
      </FormikSelectorContextProvider>
    </Formik>
  );
}

export default React.memo(Form) as typeof Form;
