import { customTypeRenderer } from '../../../components/renderers';
import { FormElementProps, FormSlowCustomField } from '../../../types';

const SlowCustomInput = (props: FormElementProps<FormSlowCustomField>) => {
  const { field } = props;

  return customTypeRenderer({ fieldName: field.name, Component: field.Component, field });
};

export default SlowCustomInput;
