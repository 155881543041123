import { FieldType, FormSlowCustomField } from '../../types';
import { FieldInputType } from '../types';
import Input from './Input';

const SlowCustomInputField: FieldInputType<FormSlowCustomField> = {
  Input,
  type: FieldType.SlowCustom,
};

export default SlowCustomInputField;
