import { useCallback } from 'react';
import { FastFieldProps } from 'formik';

import { chain, setIn } from '@lyearn/core/utils/immutable';

const useRendererFunctions = ({
  name,
  setFieldValue,
  setStatus,
  status,
}: {
  name: string;
  setFieldValue: FastFieldProps['form']['setFieldValue'];
  setStatus: FastFieldProps['form']['setStatus'];
  status: FastFieldProps['form']['status'];
}) => {
  const onChange = useCallback(
    (nextValue: any) => {
      setFieldValue(name, nextValue, true);
    },
    [name, setFieldValue],
  );

  const setErrorElement = useCallback(
    (element: HTMLElement | null) => {
      setStatus(setIn(status ?? {}, `errorElements.${name}`, element));
    },
    [name, setStatus, status],
  );

  const setNudgeState = useCallback(
    (fieldName: string, value: string) => {
      setStatus(
        chain(status ?? {})
          .setIn(`nudge.${fieldName}`, value)
          .setIn('scrollToNudge', true)
          .value(),
      );
    },
    [setStatus, status],
  );

  return {
    onChange,
    setErrorElement,
    setNudgeState,
  };
};

export default useRendererFunctions;
