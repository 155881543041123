import React, { useCallback } from 'react';
import { FastField, FastFieldProps } from 'formik';
import isEmpty from 'lodash/isEmpty';

import { Select } from '@lyearn/ui';

import { FormFieldProps } from '../../../fields/types';
import { FieldType, FormElementProps, FormPicklistField, PicklistValue } from '../../../types';

const getOptionLabel = (option: PicklistValue) => option.label;

const SelectFastField = ({
  value: formValue,
  touched,
  isError,
  setFieldValue,
  field,
}: FormFieldProps<FormPicklistField>) => {
  const { picklistValues, placeholder, type, size } = field;
  const multiple = type === FieldType.MultiPicklist;
  const error = Boolean(touched && isError);

  const onChange = useCallback(
    (value: string | string[]) => {
      setFieldValue(field.name, value || (Array.isArray(value) ? [] : ''));
    },
    [field.name, setFieldValue],
  );

  return (
    <Select
      disableClearable
      blurOnSelect={!multiple}
      disableCloseOnSelect={multiple}
      disabled={field.disabled}
      error={error}
      getOptionLabel={getOptionLabel}
      multiple={multiple}
      options={picklistValues}
      placeholder={!multiple || isEmpty(formValue) ? placeholder : undefined}
      size={size}
      value={formValue}
      onChange={onChange}
      {...field.props}
    />
  );
};
const MemoSelectField = React.memo(SelectFastField);

const SelectField = (props: FormElementProps<FormPicklistField>) => {
  const { field } = props;

  return (
    <FastField name={field.name}>
      {({
        field: { value },
        form: { setFieldValue },
        meta: { error, touched },
      }: FastFieldProps) => (
        <MemoSelectField
          field={field}
          isError={error}
          setFieldValue={setFieldValue}
          touched={touched}
          value={value}
        />
      )}
    </FastField>
  );
};

export default React.memo(SelectField);
