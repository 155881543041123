import React from 'react';
import c from 'classnames';
import { useFormikContext } from 'formik';

import { Box, Divider } from '@lyearn/ui';

import { useFormContext } from '../../context/FormContext';
import { getFieldVisibility } from '../../helper/getFieldVisibility';
import useErrorFocus from '../../hooks/useErrorFocus';
import FormLayout from '../../layout/FormLayout';
import SectionsLayout from '../../layout/SectionsLayout';
import { SectionBasedLayout } from '../../types';
import { customTypeRenderer } from '../renderers';
import SectionHeader from './SectionHeader';

interface SectionProps {
  section: SectionBasedLayout;
  showDivider: boolean;
  index: number;
  layoutLength: number;
}

const SECTION_CLASSNAME = 'flex flex-col';

const FormSection = ({ section, showDivider, index, layoutLength }: SectionProps) => {
  const {
    description,
    name,
    layout,
    renderer,
    className,
    id,
    subSections,
    tooltipHelperText,
    classes,
    visibility,
    showDivider: sectionShowDivider,
  } = section;
  const { overrides } = useFormContext();
  const formik = useFormikContext();

  const shouldShowDivider = sectionShowDivider !== undefined ? sectionShowDivider : showDivider;

  const { fieldRef } = useErrorFocus({
    fieldName: renderer?.fieldKey,
    scrollBlockPosition: renderer?.scrollBlockPosition,
  });

  const isVisible = visibility ? getFieldVisibility(formik, visibility) : true;

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <Box
        ref={fieldRef}
        className={c(SECTION_CLASSNAME, className)}
        id={id}
        {...overrides?.Section?.Container}>
        {name || description ? (
          <SectionHeader
            description={description}
            name={name}
            overrides={overrides?.Section}
            tooltipHelperText={tooltipHelperText}
          />
        ) : null}
        {renderer ? (
          customTypeRenderer({ fieldName: renderer.fieldKey, Component: renderer.component })
        ) : subSections ? (
          <SectionsLayout layout={subSections} showDivider={false} />
        ) : (
          <FormLayout classes={classes?.layout} layout={layout!} />
        )}
      </Box>
      {shouldShowDivider && index < layoutLength - 1 ? (
        <Divider key={index} className="bg-divider" size={1} />
      ) : null}
    </>
  );
};

export default React.memo(FormSection);
