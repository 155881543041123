import { FormikContextType, useFormikContext } from 'formik';
import { Context, createContext, useContextSelector } from 'use-context-selector';

export const FormikSelectorContext = createContext<FormikContextType<any>>(undefined as any);

export const FormikSelectorContextProvider = ({ children }: { children: React.ReactNode }) => {
  const formik = useFormikContext<any>();

  if (!formik) {
    throw new Error('FormikSelectorContextProvider must be used within a Formik component');
  }

  return <FormikSelectorContext.Provider value={formik}>{children}</FormikSelectorContext.Provider>;
};

export function useFormikSelectorContext<Values, Selected>(
  selector: (formik: FormikContextType<Values>) => Selected,
) {
  const selected = useContextSelector(
    FormikSelectorContext as Context<FormikContextType<Values>>,
    selector,
  );

  return selected;
}
