import { Field as FormikField, FieldProps } from 'formik';

import useRendererFunctions from '../hooks/useRendererFunctions';
import { FormSlowCustomField, RendererParams } from '../types';

interface CustomTypeRendererParams {
  fieldName: string;
  fieldKey?: string;
  Component: FormSlowCustomField['Component'];
  field?: FormSlowCustomField;
}

type RendererType = {
  status?: any;
  Component: React.FC<RendererParams>;
  value: Omit<RendererParams, 'onChange' | 'setErrorElement' | 'setNudgeState'>;
  field?: FormSlowCustomField;
};

const Renderer = ({ Component, value, status, field }: RendererType) => {
  const { setFieldValue, setStatus, ...rest } = value;
  const { onChange, setErrorElement, setNudgeState } = useRendererFunctions({
    name: rest.name,
    setFieldValue,
    setStatus,
    status,
  });

  return Component({
    ...rest,
    setStatus,
    onChange,
    setFieldValue,
    setErrorElement,
    setNudgeState,
    field,
  });
};

export const customTypeRenderer = ({ fieldName, Component, field }: CustomTypeRendererParams) => {
  if (!Component) {
    return null;
  }

  return (
    <FormikField name={fieldName}>
      {({ field: { value, name }, meta: { error, touched }, form }: FieldProps) => {
        const {
          errors,
          values,
          status,
          setFieldValue,
          handleChange,
          setFieldError,
          setFieldTouched,
          handleBlur,
          setStatus,
        } = form;

        return (
          <Renderer
            Component={Component}
            field={field}
            status={status}
            value={{
              formik: form,
              value,
              values,
              error,
              errors,
              touched,
              name,
              setStatus,
              handleBlur,
              handleChange,
              setFieldValue,
              setFieldError,
              setFieldTouched,
            }}
          />
        );
      }}
    </FormikField>
  );
};
