import React from 'react';
import c from 'classnames';
import { FastField, FastFieldProps } from 'formik';
import get from 'lodash/get';

import { Input, useForkRef } from '@lyearn/ui';

import { nudgeBorderStyles } from '../../../styles';
import { FieldType, FormElementProps, FormTextField } from '../../../types';

const getTextType = (fieldType: FieldType) => {
  if (fieldType === FieldType.Email) {
    return 'email';
  }
  if (fieldType === FieldType.Password) {
    return 'password';
  }
  if (fieldType === FieldType.Text) {
    return 'text';
  }
  if (fieldType === FieldType.Number) {
    return 'number';
  }
};

const TextField = (props: FormElementProps<FormTextField>) => {
  const { field, className } = props;

  return (
    <FastField name={field.name}>
      {({
        field: { value, onBlur, onChange },
        meta: { error, touched },
        form: { status, isSubmitting },
      }: FastFieldProps) => {
        const nudgeMessage = get(status, `nudge.${field.name}`);

        const errorExist = !!(touched && error);

        const showError = (isSubmitting || !nudgeMessage) && errorExist;

        return (
          <Input
            className={c(className, { [nudgeBorderStyles]: !showError && nudgeMessage })}
            disabled={field.disabled}
            error={showError}
            multiline={field.type === FieldType.Multiline}
            name={field.name}
            placeholder={field.placeholder}
            rows={6}
            size={field.size}
            type={getTextType(field.type)}
            value={value}
            onBlur={onBlur}
            onChange={onChange}
            {...field.props}
          />
        );
      }}
    </FastField>
  );
};

export default React.memo(TextField);
