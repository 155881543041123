import React, { useCallback, useRef } from 'react';
import c from 'classnames';
import { FastField, FastFieldProps } from 'formik';
import noop from 'lodash/noop';
import { nanoid } from 'nanoid';

import Platform from '@lyearn/core/utils/Platform';
import { NumberRating, Stack, StarRating, Typography } from '@lyearn/ui';

import { FormFieldProps } from '../../../fields/types';
import { FormElementProps, FormRatingField, RatingType } from '../../../types';
import { ratingLabelStyles } from './styles';

const RatingField = ({ value, setFieldValue, field }: FormFieldProps<FormRatingField>) => {
  const ref = useRef(nanoid());

  const onNumberRatingChange: React.ComponentProps<typeof NumberRating>['onChange'] = useCallback(
    (value) => setFieldValue(field.name, value),
    [field.name, setFieldValue],
  );

  const onStarRatingChange = useCallback(
    (_event: React.ChangeEvent<{}>, value: number | null) => {
      setFieldValue(field.name, value);
    },
    [field.name, setFieldValue],
  );

  // TODO: add in lyearn/ui?
  const renderLabels = ({ className }: { className?: string }) => {
    if (field.props.minLabel || field.props.maxLabel) {
      return (
        <Stack
          className={c('px-8', className)}
          justifyContent={Platform.select({ web: 'between', default: undefined })}>
          <Typography
            className={c('native:w-auto w-2/5 me-8', ratingLabelStyles)}
            color="text-text-secondary"
            variant="body-short-03">
            {field.props.minLabel}
          </Typography>
          <Typography
            className={c('native:w-auto w-2/5 text-end', ratingLabelStyles)}
            color="text-text-secondary"
            variant="body-short-03">
            {field.props.maxLabel}
          </Typography>
        </Stack>
      );
    }

    return null;
  };

  const renderRating = () => {
    if (field.props?.ratingType === RatingType.Number) {
      return (
        <NumberRating
          className={c('native:flex-wrap', { 'pointer-events-none': field.disabled })}
          max={field.props?.max}
          value={parseInt(value, 10)}
          onChange={field.disabled ? noop : onNumberRatingChange}
        />
      );
    }

    return (
      <StarRating
        className="native:flex-wrap"
        disabled={field.disabled}
        max={field.props?.max}
        name={`${field.name}_${ref.current}`}
        value={parseInt(value, 10)}
        onChange={onStarRatingChange}
      />
    );
  };

  return (
    <Stack className="native:self-start w-min" direction="col">
      {renderRating()}
      {renderLabels({ className: 'mt-10' })}
    </Stack>
  );
};
const MemoizedRatingField = React.memo(RatingField);

const Rating = (props: FormElementProps<FormRatingField>) => {
  const { field } = props;

  return (
    <FastField name={field.name}>
      {({ field: { value }, form: { setFieldValue } }: FastFieldProps) => (
        <MemoizedRatingField field={field} setFieldValue={setFieldValue} value={value} />
      )}
    </FastField>
  );
};

export default Rating;
