import { useCallback } from 'react';
import { FormikContextType } from 'formik';
import get from 'lodash/get';

import { useFormikSelectorContext } from '../context/FormikContextSelector';

export const useFormFieldValue = <Values>(fieldName: string) => {
  const selector = useCallback(
    (formik: FormikContextType<Values>) => get(formik.values, fieldName),
    [fieldName],
  );

  const value = useFormikSelectorContext<Values, any>(selector);

  return value;
};

export const useFormFieldError = <Values>(fieldName: string) => {
  const errorSelector = useCallback(
    (formik: FormikContextType<Values>) => get(formik.errors, fieldName),
    [fieldName],
  );
  const touchedSelector = useCallback(
    (formik: FormikContextType<Values>) => get(formik.touched, fieldName),
    [fieldName],
  );

  const error = useFormikSelectorContext<Values, any>(errorSelector);
  const touched = useFormikSelectorContext<Values, any>(touchedSelector);

  return touched ? error : undefined;
};
