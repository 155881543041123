import React, { useState } from 'react';
import { FastField, FastFieldProps } from 'formik';

import { EyeClose, EyeOutline } from '@lyearn/icons';
import { Input, InputAdornment, LinkButton } from '@lyearn/ui';

import { FormElementProps, FormEmailField } from '../../../types';

const PasswordField = (props: FormElementProps<FormEmailField>) => {
  const [showPassword, toggleShowPassword] = useState(false);
  const { field, className } = props;

  return (
    <FastField name={field.name}>
      {({ field: { value, onBlur, onChange }, meta: { error, touched } }: FastFieldProps) => (
        <Input
          className={className}
          disabled={field.disabled}
          endAdornment={
            <InputAdornment position="end">
              <LinkButton
                aria-label="toggle password visibility"
                className="py-2 px-4 outline-none -me-10 hover:bg-transparent"
                color={'internal'}
                onClick={() => toggleShowPassword(!showPassword)}>
                {showPassword ? (
                  <EyeOutline className="h-26 w-26 text-text-placeholder xs:h-24 xs:w-24 xs:text-icon-placeholder" />
                ) : (
                  <EyeClose className="h-26 w-26 text-text-placeholder xs:h-24 xs:w-24 xs:text-icon-placeholder" />
                )}
              </LinkButton>
            </InputAdornment>
          }
          error={!!(touched && error)}
          name={field.name}
          placeholder={field.placeholder}
          size={field.size}
          type={showPassword ? 'text' : 'password'}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
        />
      )}
    </FastField>
  );
};

export default PasswordField;
