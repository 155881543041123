import { FastField, FastFieldProps } from 'formik';

import useRendererFunctions from '../../../hooks/useRendererFunctions';
import { FormCustomField, FormElementProps } from '../../../types';

interface CustomTypeRendererProps extends FastFieldProps {
  name: string;
  Component: FormCustomField['Component'];
}

const CustomInputRenderer = (props: CustomTypeRendererProps) => {
  const {
    name,
    Component,
    field: { value },
    form: { setFieldValue, setFieldError, setFieldTouched, setStatus, status },
    meta: { error, touched },
  } = props;

  const { onChange, setErrorElement, setNudgeState } = useRendererFunctions({
    name,
    setFieldValue,
    setStatus,
    status,
  });

  return Component({
    name,
    value,
    error,
    touched,
    onChange,
    setFieldError,
    setFieldTouched,
    setFieldValue,
    setErrorElement,
    setNudgeState,
  });
};

const CustomInputField = (props: FormElementProps<FormCustomField>) => {
  const { field } = props;

  return (
    <FastField name={field.name}>
      {(fastField: FastFieldProps) => (
        <CustomInputRenderer Component={field.Component} name={field.name} {...fastField} />
      )}
    </FastField>
  );
};

export default CustomInputField;
